import { makeApiRequest } from '@/src/lib/api-request'
import { useToken } from '@/src/store/token-store'
import { useUser } from '@/src/store/user-store'
import { useQuery } from '@tanstack/react-query'

export const useAccessTokenQuery = () => {
  const { refreshToken, setRefreshToken, setUser } = useUser()
  const { accessToken, setToken } = useToken()

  return useQuery({
    queryKey: ['auth', 'access-token', { refreshToken }],
    queryFn: async () => {
      const response = await makeApiRequest({
        method: 'POST',
        url: '/Auth/grantAccessToken',
        data: {
          refresh_token: refreshToken,
        },
        isAuthenticated: true,
        fallbackError: 'Error while fetching access token',
        onError: () => {
          setToken(null)
          setRefreshToken(null)
          setUser(null)
        },
      })

      setToken(response.response.token)
      setRefreshToken(response.response.refresh_token)
      return response.response.token
    },
    staleTime: 1000 *60 *30,
    enabled: accessToken === null && refreshToken !== null,
    refetchOnWindowFocus: false,
  })
}
