import { create } from 'zustand'

export interface TokenSlice {
  accessToken: string | null
  setToken: (token: string | null) => void
}

export const useToken = create<TokenSlice>((set) => ({
  accessToken: null,
  setToken: (accessToken: string | null) => {
    set({ accessToken })
  },
}))
