// request.js
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import config from './config'
import { useToken } from '@/src/store/token-store'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import { refreshAuth } from './refresh-auth'

// optionaly add base url
export const client = axios.create({
  baseURL: config.getBaseUrl(),
})
export const clientV3 = axios.create({
  baseURL: config.getBaseUrlV3(),
})

createAuthRefreshInterceptor(client, refreshAuth, {
  statusCodes: [401],
  pauseInstanceWhileRefreshing: true,
})

export const request = ({ ...options }: AxiosRequestConfig) => {
  // console.log(useToken.getState().accessToken)
  client.defaults.headers.common.Authorization = `Bearer ${
    useToken.getState().accessToken
  }`

  const onSuccess = (response: AxiosResponse) => response
  const onError = (error: AxiosError) => {
    return Promise.reject(error)
  }

  return client(options).then(onSuccess).catch(onError)
}

export const requestV3 = ({ ...options }: AxiosRequestConfig) => {
  // console.log(useToken.getState().accessToken)
  clientV3.defaults.headers.common.Authorization = `Bearer ${
    useToken.getState().accessToken
  }`

  const onSuccess = (response: AxiosResponse) => response
  const onError = (error: AxiosError) => {
    return Promise.reject(error)
  }

  return client(options).then(onSuccess).catch(onError)
}
