import { createJSONStorage, persist } from 'zustand/middleware'
import { create } from 'zustand'

export interface OnboardingSlice {
  isCompleted: boolean
  setIsCompleted: (value: boolean) => void
  languageIds: string
  setLanguageIds: (languageIds: string) => void
  interestIds: string
  setInterestIds: (interestIds: string) => void
  logout: () => void
}

export const useOnboarding = create(
  persist<OnboardingSlice>(
    (set, get) => ({
      isCompleted: false,
      setIsCompleted: (value: boolean) => {
        set({ isCompleted: value })
      },
      languageIds: '',
      setLanguageIds: (languageIds: string) => {
        set({ languageIds })
      },
      interestIds: '',
      setInterestIds: (interestIds: string) => {
        set({ interestIds })
      },
      logout: () => {
        set({ languageIds: '', interestIds: '' })
      },
    }),
    {
      name: 'onboarding-storage-lykstage',
      storage: createJSONStorage(() => localStorage),
    }
  )
)
