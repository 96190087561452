import { makeApiRequest } from '@/src/lib/api-request'

import { useOnboarding } from '@/src/store/onboarding-store'
import { useToken } from '@/src/store/token-store'
import { useUser } from '@/src/store/user-store'
import { User } from '@/src/types/user'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

export const useUserQuery = () => {
  const { setUser } = useUser()
  const { setLanguageIds, setInterestIds } = useOnboarding()
  const { accessToken } = useToken()
  return useQuery({
    queryKey: ['user', 'me', 'stripeUpdateOnboardAccount'],
    queryFn: async () => {
      const response = await makeApiRequest({
        method: 'GET',
        url: '/User/getUserDetails',
        isAuthenticated: true,
        data: {},
        fallbackError: 'Error while fetching user details',
        isV3Api:true
      })
      const user: User = response.response
      setLanguageIds(user?.languageIds !== '0' ? user.languageIds : '')
      setInterestIds(user?.interestIds !== '0' ? user.interestIds : '')
      setUser(user)
      return user
    },
    refetchOnWindowFocus: false,
    enabled: !!accessToken,
  })
}

export const useUserMutation = () => {
  return useMutation({
    mutationFn: async () => {
      const response = await makeApiRequest({
        method: 'GET',
        url: '/User/getUserDetails',
        isAuthenticated: true,
        data: {},
        fallbackError: 'Error while fetching user details',
        isV3Api:true
      })
      const user: User = response.response
      return user
    },
    onSuccess: () => {
      // queryClient.invalidateQueries({ queryKey: ['user', 'me'] })
    },
  })
}
