import { ErrorMessages, ErrorResponse } from '../types/api-error'
import CryptoJS from 'crypto-js';
export function isResponseOk(status: number): boolean {
  return 200 <= status && status < 300
}

export function toTitleCase(str: string): string {
  return str.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  )
}

export const getErrorMessageErrorResponse = (
  error: ErrorResponse,
  fallback: string
) => {
  let errorMessage
  if (error?.message && error?.message in ErrorMessages) {
    errorMessage = ErrorMessages[error.message]
  } else {
    errorMessage = fallback
  }
  return errorMessage
}

export function clearInputFile(f: HTMLInputElement) {
  if (f.value) {
    try {
      f.value = ''
    } catch (err) { }
    if (f.value) {
      //for IE5 ~ IE10
      const form = document.createElement('form'),
        parentNode = f.parentNode,
        ref = f.nextSibling
      form.appendChild(f)
      form.reset()
      parentNode?.insertBefore(f, ref)
    }
  }
}

export const getIsoDateString = () =>
  new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
    .toISOString()
    .substring(0, 16)

// Get the browser name and version
export function getBrowserName() {
  const userAgent = navigator.userAgent
  //@ts-ignore
  if ((navigator.brave && navigator.brave.isBrave()) || false) {
    return 'Brave'
  } else if (userAgent.indexOf('Firefox') !== -1) {
    return 'Firefox'
  } else if (userAgent.indexOf('MSIE') !== -1) {
    return 'Internet Explorer'
  } else if (userAgent.indexOf('Edg') !== -1) {
    return 'Edge'
  } else if (
    userAgent.indexOf('Opera') !== -1 ||
    userAgent.indexOf('OPR') !== -1
  ) {
    return 'Opera'
  } else if (userAgent.indexOf('Chrome') !== -1) {
    return 'Chrome'
  } else if (userAgent.indexOf('Safari') !== -1) {
    return 'Safari'
  }
}

// Get the device type
export function getDeviceType() {
  const userAgent: string = typeof navigator !== 'undefined' ? navigator.userAgent.toLowerCase() : '';
  if (userAgent.match(/iphone/i)) {
    return 'iPhone'
  } else if (userAgent.match(/ipad/i)) {
    return 'iPad'
  } else if (userAgent.match(/ipod/i)) {
    return 'iPod'
  } else if (userAgent.match(/android/i)) {
    return 'Android'
  } else if (userAgent.match(/windows phone/i)) {
    return 'Windows Phone'
  } else if (userAgent.match(/macintosh/i) && 'ontouchend' in document) {
    return 'Mac with Touch'
  } else if (userAgent.match(/tablet/i)) {
    return 'Tablet'
  } else if (userAgent.match(/mobile/i)) {
    return 'Mobile'
  } else {
    return 'Desktop'
  }
}

export function isPWA(): boolean {
  if (typeof window === 'undefined') {
    return false;
  }

  return (
    window.matchMedia('(display-mode: standalone)').matches ||
    (window.navigator as any)?.standalone ||
    document.referrer.includes('android-app://')
  );
}

export function formatDate(date: Date): string {
  const year = date.getUTCFullYear()
  const month = String(date.getUTCMonth() + 1).padStart(2, '0')
  const day = String(date.getUTCDate()).padStart(2, '0')
  const hours = String(date.getUTCHours()).padStart(2, '0')
  const minutes = String(date.getUTCMinutes()).padStart(2, '0')
  const seconds = String(date.getUTCSeconds()).padStart(2, '0')

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
}

export async function adBlockerDetected() {
  let adBlockEnabled = false
  const googleAdUrl = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js'
  try {
    await fetch(new Request(googleAdUrl)).catch(_ => adBlockEnabled = true)
  } catch (e) {
    adBlockEnabled = true
  } finally {
    return adBlockEnabled
  }
}

export const encryptPassword = (password: string) => {
  let secretKey = '028ea18a4700404b8a1513a711e8367fae660825fa5cfa1a94e56d348b57f3d6'
  const parsedKey = CryptoJS.enc.Hex.parse(secretKey)
  console.log(parsedKey, "parsedKey")
  // Generate a random 16-byte IV
  const iv = CryptoJS.lib.WordArray.random(16);

  // Encrypt with AES-256-CBC and PKCS7 padding
  const encrypted = CryptoJS.AES.encrypt(password, CryptoJS.enc.Hex.parse(secretKey), {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  // Combine IV and ciphertext
  const ivCiphertext = iv.concat(encrypted.ciphertext).toString(CryptoJS.enc.Base64);

  return ivCiphertext;
};

export const isAdult = (dob: string | Date): boolean => {
  const dobDate = new Date(dob); // Convert string to Date if necessary
  const today = new Date();

  const age = today.getFullYear() - dobDate.getFullYear();

  const hasHadBirthdayThisYear =
    today.getMonth() > dobDate.getMonth() ||
    (today.getMonth() === dobDate.getMonth() && today.getDate() >= dobDate.getDate());

  return hasHadBirthdayThisYear ? age >= 18 : age > 18;
};
