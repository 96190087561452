import { createJSONStorage, persist } from 'zustand/middleware';
import { create } from 'zustand';
import { secureStorage} from '../lib/encryption';
import { User } from '@/src/types/user'



// Define the UserSlice interface and Zustand store
export interface UserSlice {
  user: User | null;
  refreshToken: string | null;
  setUser: (user: User | null) => void;
  getUser: () => User | null;
  logout: () => void;
  isLoggedIn: () => boolean;
  setRefreshToken: (token: string | null) => void;
  updatePhone: (phone: string, countryCode: string) => void;
  updateVerificationStatus: (status: boolean) => void;
  updateOnboardedStatus: (status: boolean) => void;
  _hasHydrated: boolean;
  videoEventIds: string | null;
  setVideoEventIds: (videoEventIds: string | null) => void;
  getVideoEventIds: () => string | null;
}

export const useUser = create(
  persist<UserSlice>(
    (set, get) => ({
      user: null,
      refreshToken: null,
      _hasHydrated: false,
      setRefreshToken: (token: string | null) => {
        set({ refreshToken: token });
      },
      setUser: (user: User | null) => {
        set({ user });
      },
      getUser: () => {
        return get().user;
      },
      updatePhone: (phone: string, countryCode: string) => {
        set({ user: { ...get().user!, phoneNumber: phone, countryCode } });
      },
      updateVerificationStatus: (status: boolean) => {
        set({ user: { ...get().user!, mobileotpverify: status ? '1' : '0' } });
      },
      updateOnboardedStatus: (status: boolean) => {
        set({ user: { ...get().user!, isOnboarded: status ? '1' : '0' } });
      },
      logout: () => {
        set({ user: null, refreshToken: null });
      },
      isLoggedIn: () => {
        return get().user !== null && get().refreshToken !== null;
      },
      videoEventIds: '',
      setVideoEventIds: (videoEventIds: string | null) => {
        set({ videoEventIds });
      },
      getVideoEventIds: () => {
        return get().videoEventIds;
      },
    }),
    {
      name: 'secure-user-storage-lykstage',
      onRehydrateStorage: () => (state) => {
        if (state) {
          state._hasHydrated = true;
        }
      },
      storage: {
        getItem: secureStorage.getItem,
        setItem: secureStorage.setItem,
        removeItem: secureStorage.removeItem,
      },
    }
  )
);