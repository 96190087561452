import CryptoJS from 'crypto-js';

// Secret key for encryption (store it securely, not hardcoded)
const LYKK = 'SFksjnHHejkslkJNJDjkDk';

export const encryptData = (data: any) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), LYKK).toString();
};

export const decryptData = (cipherText: string) => {
  const bytes = CryptoJS.AES.decrypt(cipherText, LYKK);
  const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
  return JSON.parse(decryptedData);
};

export const secureStorage = {
  getItem: (name: string) => {
    const storedValue = localStorage.getItem(name);
    return storedValue ? decryptData(storedValue) : null;
  },
  setItem: (name: string, value: any) => {
    const encryptedValue = encryptData(value);
    localStorage.setItem(name, encryptedValue);
  },
  removeItem: (name: string) => {
    localStorage.removeItem(name);
  },
};