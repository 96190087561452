class Config {
  backendUrl =
    process.env.NEXT_PUBLIC_BACKEND_URL ||
    'https://back.lykstage.com/api_inj/index.php?'
    backendUrlV3 =
    process.env.NEXT_PUBLIC_BASE_URL_V3 || process.env.NEXT_PUBLIC_BACKEND_URL ||
    'https://back.lykstage.com/api_inj/index.php?'
    getBackendUrlV3(path: string): string {
      return `${this.backendUrlV3}${path}`
    }
  getBackendUrl(path: string): string {
    return `${this.backendUrl}/${path}`
  }

  getBaseUrl(): string {
    return (
      process.env.NEXT_PUBLIC_BASE_URL ||
      'https://back.lykstage.com/api_inj/index.php?'
    )
  }
  getBaseUrlV3(): string {
    return (
      process.env.NEXT_PUBLIC_BASE_URL_V3 || process.env.NEXT_PUBLIC_BASE_URL ||
      'https://back.lykstage.com/api_inj/index.php?'
    )
  }
}

const config = new Config()
export default config
